<template>
  <div class="baseInfo">
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        this.type === 'view'
          ? '安全标识设置查看'
          : this.type === 'add'
          ? '安全标识设置新增'
          : '安全标识设置编辑'
      "
      @head-save="headSave(1)"
      @head-submit="headSave(2)"
      @head-cancel="headCancel"
    />
    <div class="formContentBox">
      <div class="formMain">
        <div style="padding: 0 12px">
          <avue-form :option="formColumn" ref="formLayout" v-model="dataForm">
            <template slot="recordCode">
              <el-input
                v-model="dataForm.recordCode"
                maxlength="64"
                show-word-limit
                :disabled="
                  this.type == 'view' ||
                  this.type == 'edit' ||
                  dataForm.isAutomatic ||
                  dataForm.id
                "
              >
                <!--         style="padding: 10px"

                      style="width: calc(100% - 120px)" -->

                <!-- active-color="#13ce66" -->
                <!-- style="margin-right: 10px" -->

                <template slot="append">
                  <el-switch
                    v-model="dataForm.isAutomatic"
                    active-text="自动生成"
                    @change="handleSwidth"
                    :disabled="type == 'view' || type == 'edit' || dataForm.id"
                  ></el-switch>
                </template>
              </el-input>
            </template>
            <template slot="picList">
              <template v-if="this.type === 'view'">
                <el-image
                  v-for="(item, index) in fileList"
                  :key="index"
                  :src="item.url"
                  style="width: 150px; height: 110px; margin: 0 9px 8px 1px"
                  :preview-src-list="fileList.map((val) => val.url)"
                ></el-image
              ></template>
              <el-upload
                v-if="!(this.type === 'view' ? true : false)"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                :headers="headers"
                list-type="picture-card"
                accept=".jpg,.png"
                placeholder="请上传图片"
                :on-success="uploadSuccess"
                :before-upload="uploadBefore"
                :on-remove="handleRemove"
                :on-preview="handlePreview"
                :file-list="fileList"
              >
                <i class="el-icon-plus"></i>
                <div
                  v-if="!(this.type === 'view' ? true : false)"
                  slot="tip"
                  class="el-upload__tip"
                >
                  图片支持*.jpg/*.png格式,文件大小不超过5M
                </div>
              </el-upload>
            </template>
            <template slot="siList">
              <div class="content">
                <!-- <el-button
              size="small"
              type="primary"
              @click="selectSiList"
              v-loading="dialogLoading"
              @select-data="selectData"
              :disabled="this.type === 'view' ? true : false"
              >选择</el-button
            > -->
                <!-- <div class="upload-photo"> -->
                <div
                  style="
                    margin-bottom: 12px;
                    display: flex;
                    align-items: center;
                  "
                  v-for="(item, index) in bsSignListArray"
                  :key="index"
                  class="img-content"
                  @click="signShowFile(item)"
                >
                  <img :src="item.url" />
                  <!-- <el-image
              ref="img-content"
              v-for="(item, index) in bsSignListArray"
              :key="index"
              :src="item.url"
              style="width: 146px; height: 146px; margin: 0 9px 8px 1px"
              :preview-src-list="bsSignListArray.map((url) => url.url)"
            ></el-image> -->
                  <!-- v-if="this.type !== 'view'" -->
                  <!-- v-if="!(this.type === 'view' ? true : false)" -->
                  <!--  -->
                  <i
                    v-if="type === 'edit' || type === 'add'"
                    class="el-icon-circle-close delete-icon"
                    @click.stop="removeSign(item, index)"
                  ></i>
                </div>
                <el-button
                  size="small"
                  type="primary"
                  @click="selectSiList"
                  v-loading="dialogLoading"
                  @select-data="selectData"
                  v-if="type === 'edit' || type === 'add'"
                  >选择</el-button
                >
                <!-- :disabled="this.type === 'view' ? true : false" -->
              </div>

              <!-- </div> -->
            </template>
          </avue-form>
        </div>
      </div>
    </div>
    <!-- 选择标识弹窗 -->
    <el-dialog
      title="标识选择"
      :visible.sync="dialogVisibledialog"
      width="60%"
      hight="70vh"
      v-if="dialogVisibledialog"
      :beforeEnter="beforeDialogEnter"
    >
      <el-container>
        <el-main>
          <grid-head-layout
            ref="searchFrom"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            v-model="searchForm"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          >
          </grid-head-layout>
          <grid-layout
            ref="gridLayout"
            class="SignsCrud"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :data-total="page.total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :searchColumns="searchColumns"
          >
          </grid-layout>
        </el-main>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibledialog = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览现场图片 -->
    <el-dialog
      v-dialog-drag
      title="现场图片预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 预览标识清单图片 -->
    <el-dialog
      v-dialog-drag
      title="安全标识图片预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="signShowFileDialog"
      width="70%"
    >
      <iframe
        ref="signFileIframe"
        :src="signFileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="signShowFileDialog = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="预览"
      :visible.sync="dialogVisiblepic"
      append-to-body
      top="5vh"
      custom-class="customDialog"
      v-if="dialogVisiblepic"
      ><img
        style="width: 100%; height: 100%; object-fit: contain"
        :src="dialogImageUrl"
        alt=""
      />
    </el-dialog> -->
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { addRecord, getRecordDetail } from "@/api/siteManagement/safetySigns";
import { getSignList } from "@/api/bssettings/safetySignsList";
import { getPageCode } from "@/api/system/serialNumber";
import { dateFormat } from "@/util/date";
import website from "@/config/website";

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout
  },
  data() {
    return {
      // 公共数据
      type: "",
      // 安全标识设置信息
      dataForm: {
        isAutomatic: true,
      },
      searchForm: {},
      bsSignListArray: [],
      // bsRecord: true, //自动生成编码
      fileList: [], //pic
      dialogVisible: false, //pic
      dialogVisibledialog: false,
      dialogImageUrl: "", // 预览图片路径
      // 标识选择弹窗
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      selectionList: [],
      showFileDialog: false, //预览现场图片
      signShowFileDialog: false, //预览安全标识图片
      tableOptions: {
        index: true,
        indexLabel: "序号",
        menu: false,
        column: [
          {
            label: "安全标识",
            prop: "picUrl",
            align: "center",
            type: "upload",
            width: 200,
            hight: 200,
          },
          {
            label: "标识名称",
            prop: "signName",
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: "设置范围和说明",
            prop: "description",
            align: "left",
            overHidden: true,
          },
        ],
      },
      // // 标识选择弹窗
      // searchColumns: [
      //   {
      //     label: "标识名称",
      //     prop: "signName",
      //     span: 4,
      //     clearable: true,
      //     placeholder: this.$t("cip.cmn.rule.inputWarning") + "标识名称",
      //   },
      //   {
      //     label: "标识类型",
      //     prop: "signType",
      //     span: 4,
      //     type: "select",
      //     dataType: "string",
      //     clearable: true,
      //     placeholder: this.$t("cip.cmn.rule.selectWarning") + "标识类型",
      //     dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=BS_SIGN_TYPE",
      //     props: {
      //       label: "dictValue",
      //       value: "dictKey",
      //     },
      //   },
      // ],
    };
  },
  computed: {
    // 标识选择弹窗
    searchColumns() {
      return [
        {
          label: "标识名称",
          prop: "signName",
          span: 4,
          clearable: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "标识名称",
        },
        {
          label: "标识类型",
          prop: "signType",
          span: 4,
          type: "select",
          dataType: "string",
          clearable: true,
          dicData: [],
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "标识类型",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=BS_SIGN_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ];
    },
    // 安全标识设置 新增的保存、保存并返回、取消
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          btnOptType: "save",
        });
        result.push({
          label: "提交",
          emit: "head-submit",
          btnOptType: "submit",
        });
      }
      result.push({
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
      });
      return result;
    },
    // 安全标识设置 新增展现数据
    formColumn() {
      // return [
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "记录编号",
            prop: "recordCode",
            span: 9,
            formslot: true,
            disabled: !["add"].includes(this.type),
            rules: [
              {
                required: true,
                message:
                  this.$t("cip.cmn.rule.inputWarning") + "编号或由系统自动生成",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            label: "设置位置",
            prop: "location",
            span: 8,
            maxlength: 64,
            showWordLimit: true,
            disabled: !["edit", "add"].includes(this.type),
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "设置位置",
                trigger: "blur",
              },
            ],
          },
          {
            label: "设置时间",
            prop: "setDate",
            span: 7,
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
            disabled: !["edit", "add"].includes(this.type),
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning") + "设置时间",
                trigger: "change",
              },
            ],
          },
          {
            label: "现场图片",
            prop: "picList",
            type: "upload",
            span: 24,
            formslot: true,
            disabled: !["edit", "add"].includes(this.type),
            rules: [
              {
                required: true,
                // message: "请上传图片",
                validator: (rule, value, callback) => {
                  if (this.fileList.length === 0) {
                    callback(new Error("请上传安全标识图片"));
                  } else {
                    callback();
                  }
                },
                trigger: "change",
              },
            ],
          },
          {
            label: "标识清单",
            prop: "siList",
            span: 24,
            // type: "upload",
            maxlength: 500,
            showWordLimit: true,
            disabled: !["edit", "add"].includes(this.type),
          },

          {
            label: "备注",
            prop: "remark",
            span: 24,
            maxlength: 500,
            type: "textarea",
            showWordLimit: true,
            disabled: !["edit", "add"].includes(this.type),
          },
        ],
      };
    },
  },

  created() {
    let { id, type } = this.$route.query;
    this.type = type;
    if (this.type !== "add") {
      this.dataForm.id = id;
      this.initData();
    }
    if (this.type == "add") {
      this.handleSwidth(); //自动生成编号
      this.dataForm.setDate = dateFormat(new Date(), "yyyy-MM-dd");
    }
    if (this.type == "view" || this.type == "edit") {
      this.dataForm.isAutomatic = false;
    }
  },
  mounted() {
    if (this.type == "view") {
      this.$refs.formLayout.allDisabled = true;
    }
  },

  methods: {
    beforeDialogEnter() {
      if (this.dialogVisibledialog) {
        this.clearCache();
      }
    },
    clearCache() {
      this.$nextTick(() => {
        this.searchForm = {};
      });
    },

    // 删除标识清单单个图片
    removeSign(item, index) {
      // 过滤要删除的图片url
      this.bsSignListArray = this.bsSignListArray.filter((url) => {
        return url.url != item.url;
      });
      this.$set(this.dataForm, "siList", this.bsSignListArray);
    },
    // // 自动生成编号
    // handleSwidth(val) {
    //   if (val) {
    //     getPageCode({ ruleCode: "bs_record" }).then((res) => {
    //       this.dataForm.recordCode = res.data.data;
    //     });
    //   }
    // },
    // getPageCode(val) {
    //   if (val) {
    //     getPageCode({ ruleCode: "bs_record" }).then((res) => {
    //       this.dataForm.recordCode = res.data.data;
    //     });
    //   }
    // },
    handleSwidth() {
      if (this.dataForm.isAutomatic) {
        // this.getPageCode();
        getPageCode({ ruleCode: "bs_record" }).then((res) => {
          this.dataForm.recordCode = res.data.data;
          console.log(this.dataForm.recordCode, "this.dataForm.recordCode");
        });
      } else {
        this.dataForm.recordCode = "";
      }
    },
    // 上传成功 pic
    uploadSuccess(response, file, fileList) {
      this.fileList = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          return item.response.data;
        } else {
          return item;
        }
      });
      console.log(this.fileList, "picList");
      this.$refs.formLayout.clearValidate("picList");
    },
    // 文件上传超出限制回调
    uploadExceed(idCard, fileList) {
      this.$message.error("超出上传限制文件数量");
    },
    // 文件上传前的回调
    uploadBefore(file) {
      const isJPG = file.type === "image/jpg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;
      console.log(this.fileList, file, "file");
      const isTopLimit = this.fileList.length >= 9;
      if (isTopLimit) {
        this.$message.error("当前限制最多可以上传9张照片！");
        return false;
      }
      if (!isJPG) {
        this.$message.error("图片仅支持*.jpg/*.png格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不超过5M");
      }
      return isJPG && isLt2M;
    },
    // 上传失败回调
    handleError() {},

    // 文件改变时候修改提示信息
    uploadChange(uploadFile, fileList) {
      if (fileList.length !== 0) {
        this.$refs.gridLayout.validateField("fileList");
      }
    },
    // // 删除上传照片
    //     onRemove(file, fileList) {
    //       console.log(file, fileList);
    //       this.fileList = this.fileList.filter((val) => val.url != file.url);
    //     },

    // 删除文件
    handleRemove(file, fileList) {
      this.fileList = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      if (fileList.length == 0) {
        this.$refs.formLayout.validateField("fileList");
      }
    },
    // 预览
    handlePreview(file) {
      this.showFile({ picList: file.url });
    },
    //预览现场图片
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.picList));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    //预览安全标识图片
    signShowFile(item) {
      this.signFileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.url));
      this.signShowFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.signFileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    // 标识清单弹窗
    selectSiList() {
      this.query = {};
      this.page.currentPage = 1;
      this.page.pageSize = 20;
      this.selectionList = [];
      this.dialogVisibledialog = true;
      this.onLoad(this.page, this.query);
      this.searchReset();
    },
    // 标识列表加载
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getSignList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;

        this.tableData = data.records;
        this.tableLoading = false;
        this.selectionClear();
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayout.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 标识弹窗查询
    searchChange(params, done) {
      if (params.signName == "") {
        delete params.signName;
      } else if (params.signType == "") {
        delete params.signType;
      } else if (params.signName == "" && params.signType == "") {
        params = {};
      }
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    // 标识弹窗查询重置
    searchReset(params, data) {
      this.params = {};
      this.query = {};
      this.page.currentPage = 1;
      this.onLoad(this.page, this.params);
      console.log(this.params, "this.query");
    },
    // 标识弹窗选择变化
    selectionChange(list) {
      this.selectionList = list;
    },
    // 标识弹窗选择清空
    selectionClear() {
      this.selectionList = [];
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    // , this.$refs.gridHeadLayout.searchForm

    // 标识清单弹窗确定
    handleSave() {
      this.bsSignListArray = this.selectionList.map((res) => {
        return { url: res.picUrl, id: res.id };
      });
      this.query = {};
      this.dialogVisibledialog = false;
    },

    initData() {
      getRecordDetail(this.dataForm.id).then((res) => {
        const { data } = res.data;
        this.dataForm = data;
        this.fileList = this.dataForm.picList
          ? this.dataForm.picList.split(",").map((url) => {
              return { url };
            })
          : [];
        if (this.dataForm.bsSignList) {
          this.bsSignListArray = this.dataForm.bsSignList.map((item) => {
            if (item.picUrl != "") {
              return { url: item.picUrl, id: item.id };
            }
          });
        }
      });
    },

    // 保存 安全标识设置 的数据
    headSave(x) {
      this.dataForm = this.$refs.formLayout.form;
      if (this.type == "edit") {
        this.dataForm.setDate =
          this.$refs.formLayout.form.setDate + " 00:00:00";
      }
      if (x == 1) {
        this.dataForm.status = 1;
      } else if (x == 2) {
        this.dataForm.status = 2;
      }
      // 现场图片picList
      this.dataForm.picList = this.fileList
        .map((val) => {
          return val.url;
        })
        .toString();
      // 标识清单siList
      this.dataForm.siList = this.bsSignListArray
        .map((item) => {
          return item.id;
        })
        .toString();
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          addRecord(this.dataForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code === 200 && this.dataForm.status == 1) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                Object.assign(this.dataForm, data);
                this.type = "edit";
                this.onLoad(this.page);
              } else if (res.data.code === 200 && this.dataForm.status == 2) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                this.headCancel();
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            });
        }
      });
    },
    // 安全标识设置 返回
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
};
</script>

  <style scoped lang="scss">
.baseInfo {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.button-container {
  display: flex;
  align-items: center;
}
.content {
  display: flex;
  align-items: center;
  .img-content {
    width: 150px;
    height: 110px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;
    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
// .upload-photo {
//   width: 580px;
//   height: 200px;
//   box-sizing: border-box;
//   border-radius: 5px;
//   cursor: pointer;
//   margin-left: 20px;
//   margin-top: 0px;
//   padding-top: 0px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;

//   li {
//     width: 180px;
//     height: 180px;
//     margin-right: 15px;
//     position: relative;
//     .delete-img {
//       display: none;
//     }
//     &:hover {
//       .delete-img {
//         display: block;
//         position: absolute;
//         width: 180px;
//         height: 40px;
//         line-height: 40px;
//         left: 0px;
//         top: 140px;
//         background: rgba(59, 60, 61, 0.5);
//         // box-sizing: content-box;
//         z-index: 999;
//         cursor: pointer;
//         text-align: right;
//         i {
//           margin: 8px 10px 0 0;
//           display: block;
//           font-size: 24px;
//           color: white;
//         }
//       }
//     }

//     .img {
//       border: 1px dashed #d9d9d9;
//       border-radius: 5px;
//       box-sizing: border-box;
//       width: 180px;
//       height: 180px;
//       margin-top: 0px;
//       &:hover {
//         border: 1px dashed #409eff;
//       }
//     }
//   }
// }
</style>
