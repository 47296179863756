var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfo" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type === "view"
              ? "安全标识设置查看"
              : this.type === "add"
              ? "安全标识设置新增"
              : "安全标识设置编辑",
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(1)
          },
          "head-submit": function ($event) {
            return _vm.headSave(2)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "avue-form",
                {
                  ref: "formLayout",
                  attrs: { option: _vm.formColumn },
                  model: {
                    value: _vm.dataForm,
                    callback: function ($$v) {
                      _vm.dataForm = $$v
                    },
                    expression: "dataForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "recordCode" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            maxlength: "64",
                            "show-word-limit": "",
                            disabled:
                              this.type == "view" ||
                              this.type == "edit" ||
                              _vm.dataForm.isAutomatic ||
                              _vm.dataForm.id,
                          },
                          model: {
                            value: _vm.dataForm.recordCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "recordCode", $$v)
                            },
                            expression: "dataForm.recordCode",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "自动生成",
                                  disabled:
                                    _vm.type == "view" ||
                                    _vm.type == "edit" ||
                                    _vm.dataForm.id,
                                },
                                on: { change: _vm.handleSwidth },
                                model: {
                                  value: _vm.dataForm.isAutomatic,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "isAutomatic", $$v)
                                  },
                                  expression: "dataForm.isAutomatic",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "picList" },
                    [
                      this.type === "view"
                        ? _vm._l(_vm.fileList, function (item, index) {
                            return _c("el-image", {
                              key: index,
                              staticStyle: {
                                width: "150px",
                                height: "110px",
                                margin: "0 9px 8px 1px",
                              },
                              attrs: {
                                src: item.url,
                                "preview-src-list": _vm.fileList.map(
                                  (val) => val.url
                                ),
                              },
                            })
                          })
                        : _vm._e(),
                      !(this.type === "view" ? true : false)
                        ? _c(
                            "el-upload",
                            {
                              attrs: {
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                headers: _vm.headers,
                                "list-type": "picture-card",
                                accept: ".jpg,.png",
                                placeholder: "请上传图片",
                                "on-success": _vm.uploadSuccess,
                                "before-upload": _vm.uploadBefore,
                                "on-remove": _vm.handleRemove,
                                "on-preview": _vm.handlePreview,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-plus" }),
                              !(this.type === "view" ? true : false)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        "\n                图片支持*.jpg/*.png格式,文件大小不超过5M\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("template", { slot: "siList" }, [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _vm._l(_vm.bsSignListArray, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "img-content",
                              staticStyle: {
                                "margin-bottom": "12px",
                                display: "flex",
                                "align-items": "center",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.signShowFile(item)
                                },
                              },
                            },
                            [
                              _c("img", { attrs: { src: item.url } }),
                              _vm.type === "edit" || _vm.type === "add"
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-circle-close delete-icon",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.removeSign(item, index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm.type === "edit" || _vm.type === "add"
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.dialogLoading,
                                    expression: "dialogLoading",
                                  },
                                ],
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: _vm.selectSiList,
                                  "select-data": _vm.selectData,
                                },
                              },
                              [_vm._v("选择")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.dialogVisibledialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "标识选择",
                visible: _vm.dialogVisibledialog,
                width: "60%",
                hight: "70vh",
                beforeEnter: _vm.beforeDialogEnter,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibledialog = $event
                },
              },
            },
            [
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    [
                      _c("grid-head-layout", {
                        ref: "searchFrom",
                        attrs: {
                          "head-title": _vm.$t(
                            "cip.plat.sys.user.title.indexHeadTitle"
                          ),
                          "search-columns": _vm.searchColumns,
                        },
                        on: {
                          "grid-head-search": _vm.searchChange,
                          "grid-head-empty": _vm.searchReset,
                        },
                        model: {
                          value: _vm.searchForm,
                          callback: function ($$v) {
                            _vm.searchForm = $$v
                          },
                          expression: "searchForm",
                        },
                      }),
                      _c("grid-layout", {
                        ref: "gridLayout",
                        staticClass: "SignsCrud",
                        attrs: {
                          "head-title": _vm.$t(
                            "cip.plat.sys.user.title.indexHeadTitle"
                          ),
                          "data-total": _vm.page.total,
                          page: _vm.page,
                          "table-options": _vm.tableOptions,
                          "table-data": _vm.tableData,
                          "table-loading": _vm.tableLoading,
                          searchColumns: _vm.searchColumns,
                        },
                        on: {
                          "page-size-change": _vm.handleSizeChange,
                          "page-current-change": _vm.handleCurrentChange,
                          "page-refresh-change": _vm.onLoad,
                          "gird-handle-select-click": _vm.selectionChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibledialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "现场图片预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "安全标识图片预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.signShowFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.signShowFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "signFileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.signFileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.signShowFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }